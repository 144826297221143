<template>
  <nav
    class="
      header-navbar
      navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow
      fixed-top
      navbar-light navbar-bg-color
    "
  >
    <div class="navbar-wrapper">
      <div class="navbar-header d-md-none">
        <ul class="nav navbar-nav flex-row">
          <li class="nav-item mobile-menu d-md-none mr-auto">
            <a class="nav-link nav-menu-main menu-toggle hidden-xs" href="#"><i class="ft-menu font-large-1"></i></a>
          </li>
          <li class="nav-item d-md-none">
            <a class="navbar-brand" href="/"
              ><img
                class="brand-logo d-none d-md-block"
                alt="Miracle Cash&More"
                :src="logoDefault" /><img
                class="brand-logo d-sm-block d-md-none"
                alt="Miracle Cash&More"
                :src="logoMobile"
            /></a>
          </li>
          <li class="nav-item d-md-none">
            <a class="nav-link open-navbar-container" data-toggle="collapse" data-target="#navbar-mobile"
              ><i class="la la-ellipsis-v"> </i
            ></a>
          </li>
        </ul>
      </div>
      <div class="navbar-container">
        <div class="collapse navbar-collapse" id="navbar-mobile">
          <ul class="nav navbar-nav mr-auto float-left">
            <li class="nav-item d-none d-md-block">
              <a class="nav-link nav-menu-main menu-toggle hidden-xs" href="#"><i class="ft-menu"> </i></a>
            </li>
          </ul>
          <ul class="nav navbar-nav float-right">
            <li  class="dropdown dropdown-language nav-item" v-click-outside="onClickOutsideLanguage">
              <a
                @click="onLanguage"
                class="dropdown-toggle nav-link"
                id="dropdown-flag"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i
                 
                  class="flag-icon"
                  v-bind:class="getFlagClass"
                ></i>
                <span class="selected-language"></span>
              </a>
              <div v-if="languageDisplay"  class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-flag">
                <a
                  class="dropdown-item"
                  href="#"
                  v-for="language in languages"
                  :key="language.lang_code"
                  @click="changeLanguage(language.lang_code)"
                >
                  <i :class="`flag-icon flag-icon-${language.flag_code}`"></i> {{language.title}}
                </a>
              </div>
            </li>
            <li class="dropdown dropdown-notification nav-item" v-bind:class="{ show: showNotification }" v-click-outside="onClickOutsideNotifications">
              <a class="nav-link nav-link-label" href="#" @click="readNotifications"
                ><i class="ficon ft-bell"></i>
                <span
                  class="badge badge-pill badge-default badge-danger badge-default badge-up badge-glow"
                  v-if="unreadNotifications"
                >
                  {{ unreadNotifications }}
                </span>
              </a>
              <ul
                class="dropdown-menu dropdown-menu-media dropdown-menu-right"
                v-bind:class="{ show: showNotification }"
              >
                <li class="dropdown-menu-header">
                  <h6 class="dropdown-header m-0">
                    <span class="grey darken-2">{{ $t('top-menu-notification') }}</span>
                  </h6>
                  <span class="notification-tag badge badge-default badge-danger float-right m-0">
                    {{ unreadNotifications }} {{ $t('header-notification-title') }}
                  </span>
                </li>
                <li class="scrollable-container media-list w-100" style="overflow-y: scroll; height:500px;">
                  <div class="media" v-for="(item, index) in userNotifications" :key="'notifications-' + index">
                    <div class="media-left align-self-center">
                      <i class="ft-check-circle icon-bg-circle bg-cyan"></i>
                    </div>
                    <div class="media-body">
                      <h6 class="media-heading">{{ item.all_data.data.title }}</h6>
                      <p class="notification-text font-small-3 text-muted">
                        {{ item.all_data.data.message }}
                      </p>
                      <small>
                        <time class="media-meta text-muted">{{ item.time_ago }}</time>
                      </small>
                    </div>
                  </div>
                </li>
                <li class="dropdown-menu-footer">
                  <a class="dropdown-item text-muted text-center" @click="getMore">
                    {{ $t('top-menu-loadMore-notification') }}
                  </a>
                </li>
              </ul>
            </li>
            <li class="dropdown dropdown-notification nav-item">
              <router-link class="nav-link nav-link-label" to="/wallet">
                <svg id="wallet-icon" class="wallet-icon" enable-background="new 0 0 507.246 507.246" height="21" viewBox="0 0 507.246 507.246" width="21" xmlns="http://www.w3.org/2000/svg"><path d="m457.262 89.821c-2.734-35.285-32.298-63.165-68.271-63.165h-320.491c-37.771 0-68.5 30.729-68.5 68.5v316.934c0 37.771 30.729 68.5 68.5 68.5h370.247c37.771 0 68.5-30.729 68.5-68.5v-256.333c-.001-31.354-21.184-57.836-49.985-65.936zm-388.762-31.165h320.492c17.414 0 32.008 12.261 35.629 28.602h-356.121c-13.411 0-25.924 3.889-36.5 10.577v-2.679c0-20.126 16.374-36.5 36.5-36.5zm370.246 389.934h-370.246c-20.126 0-36.5-16.374-36.5-36.5v-256.333c0-20.126 16.374-36.5 36.5-36.5h370.247c20.126 0 36.5 16.374 36.5 36.5v55.838h-102.026c-40.43 0-73.322 32.893-73.322 73.323s32.893 73.323 73.322 73.323h102.025v53.849c0 20.126-16.374 36.5-36.5 36.5zm36.5-122.349h-102.025c-22.785 0-41.322-18.537-41.322-41.323s18.537-41.323 41.322-41.323h102.025z"/><circle cx="379.16" cy="286.132" r="16.658"/></svg>
                <!-- <img src="../../assets/images/icons/wallet-icon.png" width="20px" alt="wallet icon"> -->
                <!-- <i class="ficon icon-wallet"></i> -->
              </router-link>
            </li>
            <li class="dropdown dropdown-notification nav-item" v-cloak>
              <router-link class="nav-link nav-link-label user-level-link d-flex align-items-center" to="/personal/account-settings">
                <i class="ficon ft-award"></i> 
                <span class="text-bold-700" :style="{ color: statusClass() }">{{ user.level_str }}</span>
              </router-link>
            </li>
            <li class="dropdown dropdown-user nav-item">
              <a class="dropdown-toggle nav-link dropdown-user-link" href="#" data-toggle="dropdown">
                <span class="avatar avatar-online">
                  <!-- <img src="../../assets/images/portrait/small/avatar-s-1.png" alt="avatar" /> -->
                  <img src="../../assets/images/svg/person-circle.svg" alt="avatar" />
                </span>
                <span class="user-name text-bold-700 mr-1">{{ availableBalance }}</span>
              </a>
              <div class="dropdown-menu">
                <router-link class="dropdown-item" to="/personal/account-settings">
                  <i class="ft-award"></i>{{ user.fullname }}
                </router-link>
                <div class="dropdown-divider"></div>
                <router-link class="dropdown-item" to="/wallet">
                  <i class="icon-wallet"></i>{{ $t('top-menu-wallet') }}
                </router-link>
                <router-link class="dropdown-item" to="/personal/history">
                  <i class="ft-check-square"></i>{{ $t('header-profile-history') }}
                </router-link>
                <div class="dropdown-divider"></div>
                <button type="button" class="dropdown-item" @click="logoutBtn">
                  <i class="ft-power"></i> {{ $t('header-profile-logout') }}
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { languages } from '@/config/langList';

export default {
  name: 'LeftMenu',
  data() {
    return {
      showNotification: false,
      languageDisplay:false,
      languages
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.global.lang,
      userNotifications: (state) => state.customer.userNotifications,
      user: (state) => state.customer.user,
      availableBalance: (state) => state.wallet.approximate_balance,
      unreadNotifications: (state) => state.customer.unreadNotifications,
      next: (state) => state.customer.next
    }),
    getFlagClass() {
      const flagCode = this.languages[this.lang]['flag_code'] || 'gb'
      return `flag-icon-${flagCode}`;
    }
  },
  methods: {
    ...mapActions('customer', ['GET_USER', 'GET_LOGOUT', 'GET_USER_NOTIFICATIONS', 'POST_READ_NOTIFICATIONS']),
    ...mapActions('wallet', ['GET_BALANCE']),
    ...mapActions('global', ['CHANGE_LANGUAGE']),
    logoutBtn() {
      this.GET_LOGOUT();
    },
    getMore() {
      if (this.next) this.GET_USER_NOTIFICATIONS({ isNextPage: true });
    },
    readNotifications() {
      this.showNotification = !this.showNotification;
      this.languageDisplay= false;
      if (this.$store.state.customer.unreadNotifications > 0) this.POST_READ_NOTIFICATIONS();
    },
    changeLanguage(lang) {           
      this.CHANGE_LANGUAGE({ lang: lang, refresh: true });
    },
    onLanguage() {
      this.showNotification = false;
      this.languageDisplay= !this.languageDisplay;
    },
    statusClass() {
      switch (this.user.level) {
          case 20:
            return '#ffb347';
          case 30:
            return '#89E894';
          default:
              return '#6B6F82';
      }
    },
    onClickOutsideNotifications() {
      this.showNotification = false;
    },
    onClickOutsideLanguage() {
      this.languageDisplay = false;
    }
  },
  created() {
    this.GET_USER();
    this.GET_USER_NOTIFICATIONS({ isNextPage: false });
    this.GET_BALANCE();
  }
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .user-level-link {
    font-size: 11px !important;
  }
}
.wallet-icon {
  fill: #6B6F82;
}
</style>
