<template>
  <div class="content-wrapper">
    <section id="account-login" class="flexbox-container">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <!-- image -->
        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-5 col-12 p-0 text-center d-none d-md-block">
          <div class="border-grey border-lighten-3 m-0 box-shadow-0 card-account-left height-400">
            <router-link to="/">
              <img :src="logoDefault" class="card-account-img" alt="card-account-img" />
            </router-link>
          </div>
        </div>
        <!-- login form -->
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-9 col-12 p-0">
          <div class="card border-grey border-lighten-3 m-0 box-shadow-0 card-account-right">
            <div class="card-content">
              <div class="card-body p-md-3">
                <div class="d-sm-block d-md-none text-center pb-1">
                  <router-link to="/">
                    <img
                      class="brand-logo"
                      alt="Miracle Cash&More"
                      :src="logoMobile"
                    />
                  </router-link>
                </div>
                <p class="text-center h5 text-capitalize">{{ $t('login-welcome') }}</p>
                <p class="mb-3 text-center">
                  {{ loginType === 'personal' ? $t('login-title') : $t('login-titleCorporate') }}
                </p>
                <ul class="nav nav-tabs">
                  <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#personal" @click="loginTypeBtn('personal')">
                      {{ $t('login-title') }}
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#corporate" @click="loginTypeBtn('corporate')">
                      {{ $t('login-titleCorporate') }}
                    </a>
                  </li>
                </ul>
                <ValidationObserver ref="form">
                  <form class="form-horizontal form-signin" @submit.prevent="loginBtn">
                    <fieldset>
                      <div class="input-group">
                        <multiselect
                          v-model="$store.state.customer.phoneCode"
                          :placeholder="$t('login-phone-code')"
                          label="title"
                          track-by="title"
                          :options="countries"
                          :option-height="104"
                          :custom-label="customLabel"
                          :allow-empty="false"
                          :show-labels="false"
                          class="phoneCode"
                          open-direction="bottom"
                        >
                          <template slot="singleLabel" slot-scope="props">
                            <div class="option">
                              <img class="option__image" :src="props.option.img" :alt="props.option.title" />
                              <span class="option__title">{{ props.option.code }}</span>
                            </div>
                          </template>
                          <template slot="option" slot-scope="props">
                            <div class="option">
                              <img class="option__image" :src="props.option.img" :alt="props.option.title" />
                              <span class="option__title">{{ props.option.code }}</span>
                            </div>
                          </template>
                          <template slot="noOptions">{{ $t('noOptions') }}</template>
                        </multiselect>
                        <validation-provider
                          :name="$t('login-phone')"
                          :rules="`required|min: ${minPhoneCount}`"
                          v-slot="{ classes, errors }"
                          class="form-label-group -wd"
                        >
                          <the-mask
                            class="form-control"
                            :class="classes"
                            type="tel"
                            id="phone"
                            v-model="login.phone"
                            :placeholder="$t('login-phone')"
                            :mask="[...$store.state.customer.phoneCode.phoneMask]"
                          />
                          <label for="phone">{{ $t('login-phone') }}</label>
                          <span class="error__message">{{ errors[0] }}</span>
                        </validation-provider>
                      </div>
                    </fieldset>
                    <validation-provider :name="$t('login-password')" rules="required" v-slot="{ classes, errors }">
                      <fieldset class="form-label-group">
                        <i
                          class="showPassword"
                          @click="switchPasswordType()"
                          v-bind:class="passwordFieldType === 'password' ? 'passwordEyeOff' : 'passwordEye'"
                        ></i>
                        <input
                          :type="passwordFieldType"
                          class="form-control"
                          :class="classes"
                          id="user-password"
                          v-model="login.password"
                          :placeholder="$t('login-password')"
                          @keyup.enter="loginBtn"
                        />
                        <label for="user-password">{{ $t('login-password') }}</label>
                        <span class="error__message">{{ errors[0] }}</span>
                      </fieldset>
                    </validation-provider>
                    <div class="form-group row">
                      <div class="col-md-6 col-12 text-center text-sm-left"></div>
                      <div class="col-md-6 col-12 float-sm-left text-center text-sm-right">
                        <router-link to="/forgot-password" class="card-link">
                          {{ $t('login-btnForgotPassword') }}
                        </router-link>
                      </div>
                    </div>
                    <button type="button" @click="loginBtn" class="btn-gradient-primary btn-block my-1 text-capitalize">
                      {{ $t('register-btnIndividualLogin') }}
                    </button>
                    <!-- <router-link to="/register" class="btn btn-outline-primary btn-block full-radius">
                      {{ $t('register-btnIndividualRegister') }}
                    </router-link> -->
                  </form>
                </ValidationObserver>
              </div>
              <div class="form-group row">
                <language />
              </div>
            </div>
          </div>
        </div>
        <modal v-if="showOtpModal" ref="modalName">
          <template v-slot:header>
            <h1>{{ $t('login-2faGoogleModalTitle') }}</h1>
          </template>
          <template v-slot:body>
            <ValidationObserver ref="otpForm">
              <form class="form-horizontal form-signin" @submit.prevent="loginOtpBtn">
                <div class="alert alert-warning alert-dismissible fade show" role="alert">
                  <strong>{{ $t('login-titleOTP') }}</strong>
                  <p>{{ otp.message }}</p>
                </div>
                <validation-provider :name="otpType(otp.command)" rules="required" v-slot="{ classes, errors }">
                  <fieldset class="form-label-group">
                    <input
                      type="tel"
                      class="form-control otp-code-input"
                      :class="classes"
                      id="otpCode"
                      maxlength="6"
                      v-model="otp.code"
                      :placeholder="otpType(otp.command)"
                      value=""
                      required=""
                      autofocus=""
                      ref="otpCode"
                      v-on:keydown.enter.prevent=""
                    />
                    <label for="otpCode">{{ otpType(otp.command) }}</label>
                    <span class="error__message">{{ errors[0] }}</span>
                  </fieldset>
                </validation-provider>
                <div class="row justify-content-center mt-3">
                  <div class="col-12">
                    <button type="button" @click="loginOtpBtn" class="btn-gradient-primary btn-block text-capitalize">
                      {{ $t('register-btnIndividualLogin') }}
                    </button>
                    <button type="button" @click="reSendOtpBtn" class="btn btn-outline-primary round btn-block">
                      {{ $t('login-btnSendOTPAgain') }}
                    </button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </template>
        </modal>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Modal from '@/components/global/Modal';
import Language from '@/components/global/Language';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import isDev from '@/config/isDev';

export default {
  name: 'Login',
  data() {
    return {
      loginType: 'personal',
      passwordFieldType: 'password',
      minPhoneCount: 7
    };
  },
  components: {
    Modal,
    Multiselect,
    Language
  },
  methods: {
    ...mapActions('customer', ['POST_LOGIN', 'POST_LOGIN_OTP', 'GET_COUNTRIES', 'POST_SEND_CODE', 'GET_CHAT_INFO']),
    ...mapActions('global', ['CHANGE_LANGUAGE']),
    loginBtn() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        this.POST_LOGIN(this.loginType);
      });
    },
    loginOtpBtn() {
      this.$refs.otpForm.validate().then((success) => {
        if (!success) {
          return;
        }
        this.POST_LOGIN_OTP();
      });
    },
    reSendOtpBtn() {
      this.POST_SEND_CODE();
      this.$nextTick(function () {
        this.$refs.otpCode.focus();
      });
    },
    otpType(type) {
      switch (type) {
        case 20:
          return this.$t('login-otp-code');
        case 30:
          return this.$t('login-2faGoogleAuth-title');
        case 40:
          return this.$t('login-2faEmail-title');
        default:
          return this.$t('login-otp-code');
      }
    },
    loginTypeBtn(type) {
      this.loginType = type;
    },
    customLabel({ title, desc }) {
      return `${title} – ${desc}`;
    },
    switchPasswordType() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    setMinPhoneCount() {
      // this.login.phone = '';
      const arrayList = this.phoneCode.phoneMask;
      let countArray = [];
      arrayList.forEach(function (elem) {
        // countArray.push(elem.split('#').length - 1);
        countArray.push(elem.replace(/[^#]/g, '').length);
      });
      // this.minPhoneCount = Math.min(...countArray) - 1;
      this.minPhoneCount = Math.min(...countArray);
    }
  },
  computed: {
    ...mapState({
      login: (state) => state.customer.login,
      showOtpModal: (state) => state.customer.showOtpModal,
      otp: (state) => state.customer.otp,
      isLoading: (state) => state.customer.isLoading,
      countries: (state) => state.customer.countries,
      phoneCode: (state) => state.customer.phoneCode,
      lang: (state) => state.global.lang,
      chatInfo: (state) => state.customer.chatInfo
    }),
    currentInfosetApiKey: {
      get() {
        return this.$store.state.customer.currentInfosetApiKey;
      },
      set(value) {
        this.$store.commit('customer/updateApiKey', value);
      }
    }
  },
  created() {
    this.$store.commit('customer/SET_CHAT_BOOT_API_KEY');
    this.GET_COUNTRIES().then(() => {
      this.setMinPhoneCount();
    }).catch(() => {})
    this.GET_CHAT_INFO().then(() => {
      if(!isDev) {
        // eslint-disable-next-line no-undef
        InfosetChat('boot',{widget:{apiKey: this.currentInfosetApiKey},visitor: {id: this.chatInfo.id, userHash: this.chatInfo.hashId}});
      }
    }).catch(() => {})
  },
  watch: {
    showOtpModal: {
      handler(showModal) {
        if (showModal) {
          this.$nextTick(function () {
            this.$refs.otpCode.focus();
          });
        }
      }
    },
    otp: {
      handler(otp) {
        this.$nextTick(function () {
          let otpCodeLength = this.$refs.otpCode.getAttribute('maxlength');
          otpCodeLength = parseInt(otpCodeLength);
          if (otp.code.length === otpCodeLength) {
            this.loginOtpBtn();
          }
        });
      },
      deep: true
    },
    phoneCode: {
      handler() {
        this.setMinPhoneCount();
      }
    },
    $route: function() {
      this.GET_COUNTRIES();
    }
  }
};
</script>
<style lang="scss">
.multiselect {
  &.phoneCode {
    width: 110px;
  }

  &__tags {
    height: 54.5px;
    padding: 8px 30px 0 8px;
    border-radius: 5px;

    border: 1px solid #cacfe7;
    color: #3b4781;
    line-height: 1.5rem;
  }

  &__input,
  &__single {
    min-height: 37px;
    line-height: 37px;
  }

  &__select {
    height: 50px;
  }

  .option {
    display: flex;
    align-items: center;

    &__image {
      margin-right: 5px;
      height: 13px;
    }

    &__title {
      font-size: 12px;
    }
  }

  &__placeholder {
    line-height: 34px;
    color: #aeb5da;
  }
}

.phoneCode .multiselect__tags {
  border-radius: 5px 0 0 5px;
  border-right: 0;
}

.otp-code-input {
  font-size: 20px;
  letter-spacing: 5px;
}

.-wd {
  width: calc(100% - 110px);

  .form-control {
    border-radius: 0 5px 5px 0;
  }
}

.card-account-img {
  max-width: 200px;
  width: 100% !important;
  padding-right: 20px;
}
.flexbox-container#account-login {
  height: auto !important;
  padding-top: 70px;
}
@media only screen and (max-width: 600px) {
  .blank-page .content-wrapper .flexbox-container {
    height: auto;
  }
  html body.fixed-navbar {
    padding-top: 1rem;
  }
}
</style>
